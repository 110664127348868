body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bottomlabel {
    font-size: x-small;
}

.panel{
    background-color: #e7e9ea;
}

.orbtn{
    margin-right: 5px;
    background-color: #525DF4;
    border-color: #525DF4;
}
.orbtn:hover{
    color: #52e9f4;
}
